import { Analytics } from "@vercel/analytics/react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, Route, Routes, useParams } from "react-router-dom";
import "./App.css";
import Footer from "./components/Footer";
import Home from "./components/Home";
import { Navbar } from "./components/Navbar";
import Privacy from "./components/privacy";
import { Providers } from "./components/Provider";

function App() {
  useEffect(() => {
    document.title = "GameTask";
  }, []);

  return (
    <>
      <Analytics></Analytics>
      <Providers>
        <Navbar />

        <Routes>
          {/* Redirect to default language */}
          <Route path="/" element={<Navigate to="/en" />} />

          {/* Language-specific routes */}
          <Route path="/:lang/*" element={<LanguageWrapper />} />
        </Routes>

        <Footer></Footer>
      </Providers>
    </>
  );
}

const LanguageWrapper = () => {
  const { lang } = useParams();
  const { i18n } = useTranslation();

  useEffect(() => {
    if (lang && i18n.language !== lang) {
      setTimeout(() => {
        i18n.changeLanguage(lang);
        console.log("lang", lang);
      }, 0);
    }
  }, [lang, i18n.language, i18n]);

  return (
    <Routes>
      {/* Add your app's routes here */}
      <Route path="/" Component={Home} />
      <Route path="/privacy" Component={Privacy} />
      <Route path="*" element={<Home />} />
    </Routes>
  );
};

export default App;
