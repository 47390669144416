"use client";
import { Disclosure } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Button from "./Button";
import LanguageSelector from "./LanguageSelector";

export const Navbar = () => {
  const { t, i18n } = useTranslation();

  const navigation = [t("howDoesWork")];

  const handleBook = () => {
    if (i18n.language === "es")
      window.open("https://calendly.com/gametask/demoes", "_blank");
    else window.open("https://calendly.com/gametask/demo", "_blank");
  };

  return (
    <div className="w-full bg-lightGray shadow-md z-50 lg:fixed relative ">
      <nav className="container relative flex flex-wrap items-center justify-between max-w-7xl py-2 mx-auto lg:justify-between ">
        {/* Logo  */}
        <Disclosure>
          {({ open }) => (
            <>
              <div className="flex flex-wrap   w-full lg:w-auto px-2 lg:px-0">
                <Link to="/" className="flex-1">
                  <img src="/img/logo.png" alt="N" width="150" height="60" />
                </Link>

                <Disclosure.Button
                  aria-label="Toggle Menu"
                  className="px-2 py-1 ml-auto text-gray-500 rounded-md lg:hidden hover:text-secondary focus:text-secondary focus:bg-indigo-100 focus:outline-none dark:text-gray-300 dark:focus:bg-trueGray-700"
                >
                  <svg
                    className="w-6 h-6 fill-current"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                  >
                    {open && (
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"
                      />
                    )}
                    {!open && (
                      <path
                        fillRule="evenodd"
                        d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
                      />
                    )}
                  </svg>
                </Disclosure.Button>

                <Disclosure.Panel className="flex flex-wrap w-full my-5 lg:hidden px-4">
                  <>
                    {navigation.map((item, index) => (
                      <a
                        key={index}
                        href="#howWorks"
                        className="w-full px-4 py-4 -ml-4 text-gray-500 rounded-md dark:text-gray-300 hover:text-secondary focus:text-secondary focus:bg-indigo-100 dark:focus:bg-gray-800 focus:outline-none"
                      >
                        {item}
                      </a>
                    ))}
                    <Button
                      onClick={handleBook}
                      title="bookDemo"
                      className="mb-2"
                    ></Button>

                    <LanguageSelector></LanguageSelector>
                  </>
                </Disclosure.Panel>
              </div>
            </>
          )}
        </Disclosure>

        {/* menu  */}

        <div className="flex-row hidden lg:flex">
          <div className="hidden text-center lg:flex lg:items-center">
            <ul className="items-center justify-end flex-1 pt-6 list-none lg:pt-0 lg:flex">
              {navigation.map((menu, index) => (
                <li className="mr-3 nav__item" key={index}>
                  <a
                    href="#howWorks"
                    className="inline-block px-4 py-2 text-md font-semibold text-gray-800 no-underline rounded-md dark:text-gray-200 hover:text-secondary focus:text-secondary focus:bg-indigo-100 focus:outline-none dark:focus:bg-gray-800"
                  >
                    {menu}
                  </a>
                </li>
              ))}
            </ul>
          </div>

          <div className="hidden mr-3 space-x-4 lg:flex nav__item">
            <Button onClick={handleBook} title="bookDemo"></Button>
          </div>

          <LanguageSelector></LanguageSelector>
        </div>
      </nav>
    </div>
  );
};
