import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Button from "./Button";

export default function Footer() {
  const { t } = useTranslation();
  const { i18n } = useTranslation();

  const handleBook = () => {
    if (i18n.language === "es")
      window.open("https://calendly.com/gametask/demoes", "_blank");
    else window.open("https://calendly.com/gametask/demo", "_blank");
  };

  return (
    <>
      <div className="bg-secondary  flex  items-center text-center flex-col p-8 lg:p-12 relative z-40">
        <h3 className="text-white text-3xl font-bold mb-4 ">
          {t("footer.title")}
        </h3>
        <p className="text-white mb-6 text-lg">{t("footer.subtitle")}</p>

        <Button onClick={handleBook} title="bookDemo"></Button>
      </div>
      <div className="bg-[#232632] py-4 px-4 lg:px-0 ">
        <div className="container items-center flex gap-6">
          <img src="/img/footer-logo.png" width={150} alt="Footer logo" />
          <div className="max-w-7xl ">
            <Link
              to={`/${i18n.language}/privacy`}
              className="text-white  hover:underline"
            >
              {t("footer.privacy")}
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
